// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/DateTime.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/DateTime.tsx");
  import.meta.hot.lastModified = "1718370225242.9832";
}
// REMIX HMR END

import { Fragment, useEffect, useState } from "react";
import { useLocales } from "./LocaleProvider";
export const DateTime = ({
  date,
  timeZone,
  includeSeconds = true,
  includeTime = true,
  showTimezone = false
}) => {
  _s();
  const locales = useLocales();
  const realDate = typeof date === "string" ? new Date(date) : date;
  const initialFormattedDateTime = formatDateTime(realDate, timeZone ?? "UTC", locales, includeSeconds, includeTime);
  const [formattedDateTime, setFormattedDateTime] = useState(initialFormattedDateTime);
  useEffect(() => {
    const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    setFormattedDateTime(formatDateTime(realDate, timeZone ?? resolvedOptions.timeZone, locales, includeSeconds, includeTime));
  }, [locales, includeSeconds, realDate]);
  return <Fragment>
      {formattedDateTime.replace(/\s/g, String.fromCharCode(32))}
      {showTimezone ? ` (${timeZone ?? "UTC"})` : null}
    </Fragment>;
};
_s(DateTime, "K9gVkoF11b0mPV4I7CWmNcom0kY=", false, function () {
  return [useLocales];
});
_c = DateTime;
export function formatDateTime(date, timeZone, locales, includeSeconds, includeTime) {
  return new Intl.DateTimeFormat(locales, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: includeTime ? "numeric" : undefined,
    minute: includeTime ? "numeric" : undefined,
    second: includeTime && includeSeconds ? "numeric" : undefined,
    timeZone
  }).format(date);
}
export const DateTimeAccurate = ({
  date,
  timeZone = "UTC"
}) => {
  _s2();
  const locales = useLocales();
  const realDate = typeof date === "string" ? new Date(date) : date;
  const initialFormattedDateTime = formatDateTimeAccurate(realDate, timeZone, locales);
  const [formattedDateTime, setFormattedDateTime] = useState(initialFormattedDateTime);
  useEffect(() => {
    const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
    setFormattedDateTime(formatDateTimeAccurate(realDate, resolvedOptions.timeZone, locales));
  }, [locales, realDate]);
  return <Fragment>{formattedDateTime.replace(/\s/g, String.fromCharCode(32))}</Fragment>;
};
_s2(DateTimeAccurate, "K9gVkoF11b0mPV4I7CWmNcom0kY=", false, function () {
  return [useLocales];
});
_c2 = DateTimeAccurate;
function formatDateTimeAccurate(date, timeZone, locales) {
  const formattedDateTime = new Intl.DateTimeFormat(locales, {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone,
    // @ts-ignore this works in 92.5% of browsers https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_datetimeformat_options_parameter_options_fractionalseconddigits_parameter
    fractionalSecondDigits: 3
  }).format(date);
  return formattedDateTime;
}
var _c, _c2;
$RefreshReg$(_c, "DateTime");
$RefreshReg$(_c2, "DateTimeAccurate");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;